import { OFFER_STATUS } from '@/enums';
import { api } from '@/axios/instance/api';

/**
 * Returns the first page of offers with specified status.
 * @param {'draft' | 'pending' | 'approved'} offerStatus Offer status to be filtered
 * @param {String} searchKeyword A word to match offers against
 * @returns List of offers with specified status
 */
export const getOfferList = (offerStatus, searchKeyword) => {
  let params = {
    latest_offer: true,
  };

  if (searchKeyword) {
    params.search = searchKeyword;
  }

  if (offerStatus) {
    switch (offerStatus) {
      case OFFER_STATUS.DRAFT:
        params = {
          ...params,
          is_waiting_or_rejected: true,
          is_created_by_not_null: true,
        };
        break;
      case OFFER_STATUS.PENDING:
        params = {
          ...params,
          is_sent_back: true,
        };
        break;
      case OFFER_STATUS.APPROVED:
        params = {
          ...params,
          is_approved: true,
        };
        break;
      default:
        throw new Error(`Non-existent status: ${offerStatus} `);
    }
  }

  return api.get('offers', { params });
};

export const getOfferStats = () => api.get('offers/offer-stats');
