<template>
  <button :type="nativeType" :class="classes" v-bind="$attrs" :disabled="$attrs.disabled || loading" v-on="$listeners">
    <div :class="{ invisible: loading }"><slot></slot></div>
    <RingLoader
      :loading="loading"
      :style="loaderStyle"
      :color="loaderColor"
      class="left-0 right-0 top-0 ml-auto mr-auto pt-1"
      :size="loaderSize"
    />
  </button>
</template>

<script>
import RingLoader from '@/components/atoms/ring-loader.vue';
import { get } from 'lodash-es';
import { getColor } from '@/utils/tailwind';

const getColorDeep = (color) => {
  const baseColor = color.split('-').slice(0, -1).join('-');
  const colorNumber = color.split('-').pop();

  return get(getColor(baseColor), colorNumber);
};

const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PRIMARY_OUTLINE: 'primary-outline',
  SECONDARY_OUTLINE: 'secondary-outline',
  ERROR: 'error',
  SUCCESS: 'success',
  PRIMARY_LINK: 'primary-link',
};

const BUTTON_NATIVE_TYPES = ['button', 'submit', 'reset'];

const DEFAULT_CLASSES = `relative outline-none disabled:opacity-60 disabled:cursor-default disabled:pointer-events-none`;

const BUTTON_CLASSES = {
  [BUTTON_TYPES.PRIMARY]: 'text-surface bg-primary hover:bg-accent active:bg-p-blue-80',
  [BUTTON_TYPES.SECONDARY]: 'bg-p-gray-20 hover:bg-p-gray-30 active:bg-p-gray-40',
  [BUTTON_TYPES.PRIMARY_OUTLINE]:
    'text-p-purple-60 bg-p-blue-10 border border-p-purple-60 hover:bg-p-gray-20 focus:ring-1 focus:ring-p-purple-60',
  [BUTTON_TYPES.SECONDARY_OUTLINE]: 'text-p-purple-60 bg-surface border border-p-gray-30 hover:bg-p-blue-10',
  [BUTTON_TYPES.ERROR]: 'text-white bg-p-red-40 hover:bg-p-red-50',
  [BUTTON_TYPES.SUCCESS]: 'text-white bg-p-green-40 hover:bg-p-green-50',
  [BUTTON_TYPES.PRIMARY_LINK]: 'text-primary bg-transparent hover:text-accent',
};

const BUTTON_SIZES = {
  sm: 'py-1 px-2 rounded font-medium text-sm',
  md: 'py-2 px-6 rounded font-medium text-base',
  lg: 'py-3 px-6 rounded font-medium text-lg',
};

const LOADER_SIZES = {
  sm: 24,
  md: 30,
  lg: 40,
};

const LOADER_COLORS = {
  [BUTTON_TYPES.PRIMARY]: 'p-blue-15',
  [BUTTON_TYPES.SECONDARY]: 'p-purple-60',
  [BUTTON_TYPES.PRIMARY_OUTLINE]: 'p-blue-60',
  [BUTTON_TYPES.SECONDARY_OUTLINE]: 'p-purple-60',
  [BUTTON_TYPES.ERROR]: 'p-blue-15',
  [BUTTON_TYPES.SUCCESS]: 'p-blue-15',
  [BUTTON_TYPES.PRIMARY_LINK]: 'p-blue-60',
};

export default {
  name: 'PBtn',
  components: {
    RingLoader,
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return Object.values(BUTTON_TYPES).includes(value);
      },
    },
    nativeType: {
      type: String,
      default: 'button',
      validator(value) {
        return BUTTON_NATIVE_TYPES.includes(value);
      },
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return Object.keys(BUTTON_SIZES).includes(value);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaderStyle: { position: 'absolute' },
    };
  },
  computed: {
    classes() {
      return `${DEFAULT_CLASSES} ${BUTTON_SIZES[this.size]} ${BUTTON_CLASSES[this.type]}`;
    },
    loaderSize() {
      return Number(`${LOADER_SIZES[this.size]}`);
    },
    loaderColor() {
      return getColorDeep(LOADER_COLORS[this.type]);
    },
  },
};
</script>
