import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';

import { apiCreateCandidate, apiEditCandidate, fetchCandidates } from '@/offers/api/offers-common';

const protocol = window.location.protocol + '//';
const split = window.location.host.split(':');
const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
const urlBase = protocol + newHost;

const state = {
  candidate: {},
  isLoading: false,
};

const getters = {
  getField,
  candidate: (state) => {
    return state.candidate;
  },
  isLoading: (state) => state.isLoading,
};

const mutations = {
  updateField,
  setCandidate(state, candidate) {
    state.candidate = candidate;
  },

  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
};

const actions = {
  async getCandidate(context, data) {
    try {
      const params = { params: data };
      const response = await fetchCandidates(params);
      const candidate = response.data.length > 0 ? response.data[0] : {};

      context.commit('setCandidate', candidate);
    } catch (error) {
      console.error(error);
    }
  },
  setCandidate(context, candidate) {
    context.commit('setCandidate', candidate);
  },
  async createCandidate(context, payload) {
    try {
      const response = await apiCreateCandidate(payload);

      context.commit('setCandidate', response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async createMultipleCandidates({ dispatch }, data) {
    await axios.post(urlBase + '/api/candidates/', data);
  },
  async editCandidate(context, payload) {
    try {
      const response = await apiEditCandidate(payload);
      context.commit('editCandidate', response.data);
      context.commit('setCandidate', response.data);
    } catch (error) {
      console.error(error);
    }
  },
  setIsLoading(context, value) {
    context.commit('setIsLoading', value);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
