import store from '@/store';
import {
  ACCESS_TOKEN,
  REFRESH_ROUTE,
  REFRESH_TOKEN,
  TOKENS_EXPIRY_ERROR,
  TOKENS_INVALID_ERROR,
} from '@/utils/constants.js';
import { PEQUITY_BASE_URL } from '@/environment';
import { baseControllers } from '@/axios/controllers.js';
import { debounce } from 'lodash-es';
import { router } from '@/router';
import { tokenExpired } from '@/helpers.js';

const logout = debounce(async () => {
  for (const controller of Object.values(baseControllers)) {
    try {
      if (controller) {
        controller.abort();
      }
    } catch {
      // ignore
    }
  }

  const token = localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  try {
    await store.dispatch('auth/logout');
  } catch {
    // noop
  }
  const expired = Boolean(token || refreshToken);
  if (router.currentRoute && (!['welcome-back', 'mfa-token'].includes(router.currentRoute.name) || expired)) {
    const redirect = store.getters['auth/loginRedirectPath'](router.currentRoute);
    router.push({ name: 'welcome-back', query: { redirect, ...router.currentRoute.query } });
    if (expired) {
      router.go();
    }
  }
}, 250);

const refreshTokens = (() => {
  let loading = false;
  let refreshPromise = null;

  return () => {
    if (loading) {
      return refreshPromise;
    }
    loading = true;
    refreshPromise = new Promise((resolve, reject) =>
      store
        .dispatch('auth/refreshToken')
        .then(() => resolve())
        .catch(() => reject(new Error()))
        .then(() => {
          loading = false;
        })
    );
    return refreshPromise;
  };
})();

export const useTokenInterceptors = (instance) => {
  instance.interceptors.request.use(
    async (config) => {
      const url = new URL(config.url, config.baseURL).href;

      if (url.includes(PEQUITY_BASE_URL)) {
        baseControllers[url] = new AbortController();
        config.signal = baseControllers[url].signal;
        let token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
          if (tokenExpired(token)) {
            if (tokenExpired(localStorage.getItem(REFRESH_TOKEN))) {
              throw Error(TOKENS_EXPIRY_ERROR);
            }
            try {
              await refreshTokens();
              token = localStorage.getItem(ACCESS_TOKEN);
            } catch (error) {
              throw Error(TOKENS_INVALID_ERROR);
            }
          }
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.message === TOKENS_EXPIRY_ERROR || error?.message === TOKENS_INVALID_ERROR) {
        logout();
        return Promise.reject(error);
      }

      let url;
      try {
        url = new URL(error?.config?.url, error?.config?.baseURL).href;
      } catch (err) {
        url = '';
      }

      if (url.includes(PEQUITY_BASE_URL) && error?.response?.status === 401) {
        if (url.includes(REFRESH_ROUTE)) {
          logout();
          return Promise.reject(error);
        }
        try {
          await refreshTokens();
          return instance(error.config);
        } catch {
          logout();
        }
      }
      return Promise.reject(error);
    }
  );
};
