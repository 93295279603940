<template>
  <div class="flex h-full flex-col">
    <div class="overflow-y-auto px-6">
      <PAlert v-if="hasErrorSending" type="error" class="mt-6"
        ><span class="text-xs font-bold">Failed to submit ticket. </span>
        <span class="text-xs font-medium">Please try again.</span>
      </PAlert>
      <div class="py-6">
        <h3 class="text-2xl font-semibold leading-7">{{ title }}</h3>
      </div>
      <div class="mb-6">
        <p class="text-p-gray-40">{{ descriptionText }}</p>
      </div>
      <div class="mb-6">
        <HelpFormInput
          v-model="subject"
          mode="input"
          label="Subject"
          required
          :placeholder="subjectPlaceholder"
          maxlength="100"
        />
      </div>
      <div class="mb-6">
        <HelpFormInput
          v-model="description"
          mode="text-area"
          label="Description"
          required
          :placeholder="descriptionPlaceholder"
          maxlength="2000"
        />
      </div>
      <HelpFileuploadInput class="mb-6" @validated="validatedFiles" />
    </div>
    <div class="mt-auto border-t border-p-gray-30 p-6 text-right">
      <PBtn type="secondary" class="mr-2" @click="cancel"> Cancel</PBtn>
      <PBtn :disabled="disableSendBtn" :loading="isSending" @click="createNewTicket">Submit Ticket</PBtn>
    </div>
  </div>
</template>

<script>
import HelpFileuploadInput from '@/help/components/help-fileupload-input.vue';
import HelpFormInput from '@/help/components/help-form-input.vue';
import PAlert from '@squirrel/components/p-alert/p-alert.vue';
import PBtn from '@squirrel/components/p-btn/p-btn.vue';
import { createTicket } from '@/help/api/help';

export default {
  name: 'HelpForm',
  components: { PAlert, HelpFileuploadInput, HelpFormInput, PBtn },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  emits: ['cancel', 'error', 'ticket-created'],
  data() {
    return {
      subject: '',
      description: '',
      image: '',
      hasErrorSending: false,
      isSending: false,
      filesTobeUploaded: [],
    };
  },
  computed: {
    subjectPlaceholder() {
      return this.mode === 'support' ? 'E.g. I’m having trouble adding a user' : 'E.g. How to benchmark a role';
    },
    descriptionPlaceholder() {
      return this.mode === 'support'
        ? 'E.g. I am encountering an error when assigning specific permissions for a a new user.'
        : 'E.g. I’m not sure exactly how to benchmark a QA Engineering role and was hoping you could advise';
    },
    descriptionText() {
      return this.mode === 'support'
        ? `Tell us a little more about your situation and we’ll get in touch as soon as
        possible.`
        : `Need to reach our compensation experts? Tell us a little more about your situation and we’ll get in touch as soon as possible.`;
    },
    title() {
      return this.mode === 'support' ? 'Contact Pequity’s Support Team' : 'Ask a Compensation Expert';
    },
    disableSendBtn() {
      return !this.subject || !this.description || this.isSending;
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    async createNewTicket() {
      this.hasErrorSending = false;
      this.isSending = true;

      const payload = new FormData();
      payload.append('subject', this.subject);
      payload.append('comment', this.description);
      payload.append('tag', this.mode);

      this.filesTobeUploaded.forEach((file) => payload.append('files', file));

      const { error } = await createTicket(payload);

      if (!error) {
        this.$emit('ticket-created', true);
      } else {
        this.hasErrorSending = true;
        this.isSending = false;
        this.$emit('error');
      }
    },
    validatedFiles(files) {
      this.filesTobeUploaded = files;
    },
  },
};
</script>

<style scoped></style>
