// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-unused-vars
import store from '@/store';

import {
  apiExportOfferDetails,
  apiFetchCompanies,
  apiFetchOffersVsRanges,
  fetchCompensation,
  fetchOverview,
} from '@/offers/api/analytics';

const getDefaultState = () => {
  return {
    isLoading: false,
    isExporting: false,
    error: null,
    startDate: null,
    endDate: null,
    overview: {
      offers_extended: 0,
      offers_accepted: 0,
      offers_rejected: 0,
      offers_outstanding: 0,
      acceptance_rate: 0,
      average_approval_time: 0,
    },
    compensation: {
      average: {
        signOn: 0,
        base: 0,
        newHireEquity: 0,
      },
      median: {
        signOn: 0,
        base: 0,
        newHireEquity: 0,
      },
    },
    companies: {
      current_companies: {},
      competing_companies: {},
    },
    acceptedOffers: {
      equity: { below: 0, q1: 0, q2: 0, q3: 0, q4: 0, above: 0 },
      salary: { below: 0, q1: 0, q2: 0, q3: 0, q4: 0, above: 0 },
    },
    rejectedOffers: {
      equity: { below: 0, q1: 0, q2: 0, q3: 0, q4: 0, above: 0 },
      salary: { below: 0, q1: 0, q2: 0, q3: 0, q4: 0, above: 0 },
    },
    isOverviewLoading: false,
    isCompensationLoading: false,
    isOffersVsRangesLoading: false,
    isCompaniesLoading: false,
  };
};

const state = getDefaultState();

const getters = {
  getIsLoading: (state) => state.isLoading,
  isExporting: (state) => state.isExporting,
  getError: (state) => state.error,
  getStartDate: (state) => state.startDate,
  getEndDate: (state) => state.endDate,
  getOverview: (state) => state.overview,
  getCompensation: (state) => state.compensation,
  getCompanies: (state) => state.companies,
  getAcceptedOffers: (state) => state.acceptedOffers,
  getRejectedOffers: (state) => state.rejectedOffers,
  isOverviewLoading: (state) => state.isOverviewLoading,
  isCompensationLoading: (state) => state.isCompensationLoading,
  isOffersVsRangesLoading: (state) => state.isOffersVsRangesLoading,
  isCompaniesLoading: (state) => state.isCompaniesLoading,
  isAnyAnalyticsLoading: (state) =>
    state.isOverviewLoading || state.isCompaniesLoading || state.isOffersVsRangesLoading || state.isCompaniesLoading,
};

const mutations = {
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
  setIsExporting(state, exporting) {
    state.isExporting = exporting;
  },
  setError(state, error) {
    state.error = error;
  },
  setDates(state, payload) {
    state.startDate = payload.startDate;
    state.endDate = payload.endDate;
  },
  setOverview(state, overview) {
    state.overview = overview.data;
  },
  setCompensation(state, compensation) {
    const { base_average, equity_average, sign_on_average, base_median, equity_median, sign_on_median } =
      compensation.data;
    state.compensation.average.base = base_average;
    state.compensation.average.signOn = sign_on_average;
    state.compensation.average.newHireEquity = equity_average;
    state.compensation.median.base = base_median;
    state.compensation.median.signOn = sign_on_median;
    state.compensation.median.newHireEquity = equity_median;
  },
  setCompanies(state, companies) {
    const { competing_companies, current_companies } = companies.data;
    state.companies.competing_companies = competing_companies;
    state.companies.current_companies = current_companies;
  },
  setOffersVsRanges(state, offersVsRanges) {
    const {
      accepted_vs_ranges_salary,
      accepted_vs_ranges_equity,
      rejected_vs_ranges_salary,
      rejected_vs_ranges_equity,
    } = offersVsRanges.data;

    state.acceptedOffers.salary = accepted_vs_ranges_salary;
    state.acceptedOffers.equity = accepted_vs_ranges_equity;
    state.rejectedOffers.salary = rejected_vs_ranges_salary;
    state.rejectedOffers.equity = rejected_vs_ranges_equity;
  },
  setIsOverviewLoading(state, isLoading) {
    state.isOverviewLoading = isLoading;
  },
  setIsCompensationLoading(state, isLoading) {
    state.isCompensationLoading = isLoading;
  },
  setIsOffersVsRangesLoading(state, isLoading) {
    state.isOffersVsRangesLoading = isLoading;
  },
  setIsCompaniesLoading(state, isLoading) {
    state.isCompaniesLoading = isLoading;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async exportOfferDetails({ commit }, payload) {
    try {
      commit('setIsExporting', true);
      const params = {
        params: {
          ...payload.parameters,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
      const response = await apiExportOfferDetails(params);
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `offer_analytics_${payload.startDate}_${payload.endDate}.csv`;
      link.click();
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('setIsExporting', false);
    }
  },
  async setDateRange({ commit }, payload) {
    commit('setDates', payload);
  },
  async generateOverview({ commit }, payload) {
    const params = {
      params: {
        ...payload.parameters,
        startDate: state.startDate,
        endDate: state.endDate,
      },
    };
    commit('setIsOverviewLoading', true);
    const response = await fetchOverview(params);
    commit('setIsOverviewLoading', false);
    commit('setOverview', response);
  },
  async generateCompensation({ commit }, payload) {
    const params = {
      params: {
        ...payload.parameters,
        startDate: state.startDate,
        endDate: state.endDate,
      },
    };
    commit('setIsCompensationLoading', true);
    const response = await fetchCompensation(params);
    commit('setIsCompensationLoading', false);
    commit('setCompensation', response);
  },
  async fetchCompanies({ commit }, payload) {
    const params = {
      params: {
        ...payload.parameters,
        startDate: state.startDate,
        endDate: state.endDate,
      },
    };
    commit('setIsCompaniesLoading', true);
    const response = await apiFetchCompanies(params);
    commit('setIsCompaniesLoading', false);
    commit('setCompanies', response);
  },
  async fetchOffersVsRanges({ commit }, payload) {
    const params = {
      params: {
        ...payload.parameters,
        startDate: state.startDate,
        endDate: state.endDate,
      },
    };
    commit('setIsOffersVsRangesLoading', true);
    const response = await apiFetchOffersVsRanges(params);
    commit('setIsOffersVsRangesLoading', false);
    commit('setOffersVsRanges', response);
  },
  clearAnalytics({ commit }) {
    commit('resetState');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
