import store from '@/store';
import { IS_PUBLIC, NO_AUTH_NEEDED } from '@/utils/symbols';
import { TENANT, isDev } from '@/helpers';
import { isUserAdmin } from '@/store/services/getOrFetch.js';
import { redirectToV2 } from '@/utils/redirect';

/*
 * @param redirect = a function that receives `to` and `from` when guard is called
 * -- redirect should return redirection route object to be used on failure (if user is not admin)
 *
 * curried function is the one that gets called as the route guard
 * accepts the usual vue router `to`, `from` and `next`
 */
export const isAdmin =
  (redirect = () => ({ name: 'home' })) =>
  (to, from, next) =>
    isUserAdmin().then((allow) => (allow ? next() : next(redirect(to, from))));

export const multiGuard = (...guardFns) => {
  const checkGuards = (remainingGuards, to, from, next) =>
    remainingGuards?.[0]?.(to, from, (nextRoute) =>
      nextRoute ? next(nextRoute) : checkGuards(remainingGuards.slice(1), to, from, next)
    ) || next();

  return (to, from, next) => checkGuards(guardFns, to, from, next);
};

export const handleLoggedOutUsers = (to, from, next) =>
  to.meta[NO_AUTH_NEEDED] || store.state.auth.loggedIn
    ? next()
    : store.dispatch('auth/getProfile').then(() => {
        if (!store.state.auth.loggedIn) {
          store.commit('auth/setPath', to.fullPath);

          return next({ name: 'welcome-back', query: { redirect: to.fullPath } });
        }

        return next();
      });

export const handleNonPublicRoutes = async (to, from, next) => {
  if (!to.meta[IS_PUBLIC] && (from.name === 'welcome-back' || !store.getters['companies/companies'].length)) {
    await store.dispatch('companies/fetchCompanies');
  }

  return next();
};

/**
 * This guard redirects the user to the new tenant specific login page when the user tries to use the old link.
 * Eg. http://instacart.localhost:8080/login will be redirected to http://instacart.localhost:8080/welcome-back
 *
 */
export const handlePreviousTenantSpecificLoginRoute = async (to, from, next) => {
  const isUniversalLogin = to.name === 'universal-login';
  if (isUniversalLogin && TENANT) {
    return next({ name: 'welcome-back' });
  }

  return next();
};

/**
 * This guard redirects the user to the universal login page when the path is '/' and when no tenant is specified
 * Eg. http://localhost:8080 will be redirected to http://localhost:8080/login
 *
 */
export const handleUniversalLoginRedirection = async (to, from, next) => {
  if (!TENANT) {
    if (to.path === '/') {
      return next({ name: 'universal-login' });
    }
    if (to.path === '/pbe/login') {
      redirectToV2(to);
    }
  }

  return next();
};

/**
 * Bootstrap things for logged in users
 *
 */
export const loggedInActions = (to, from, next) => {
  if (store.getters['auth/getLoggedIn']) {
    initializeData();
  }
  return next();
};

/**
 * Initializes data on first load
 *
 */
let isDataInitialized = false;

async function initializeData() {
  if (!isDataInitialized) {
    isDataInitialized = true;
    store.dispatch('companies/fetchAllRegions');
    store.dispatch('companies/fetchCompanyRanges');
    store.dispatch('companies/fetchCompanyDepartments');
    store.dispatch('companies/fetchLevels');
    store.dispatch('companies/fetchJobs');
    store.dispatch('companies/fetchGeos');
    store.dispatch('companies/fetchCountries');
    if (store.getters['companies/isUsingOffersV1']) {
      store.dispatch('users/getUsersList');
      store.dispatch('offers/getOfferStats');
      store.dispatch('offers/fetchCustomFields');
      store.dispatch('offers/fetchEquityVestScheduleTypes');
      store.dispatch('approvalChains/fetchLinks');
      store.dispatch('approvalChains/fetchLinkApprovers');
      store.dispatch('approvalChains/fetchApprovalChains');
      store.dispatch('ats/fetchAtsMappingOptions');
      store.dispatch('offerLetterGenerator/fetchDocuSignUserIntegrations', store.getters['auth/getProfile'].id);
    }
    if (store.getters['companies/isUsingMerge']) {
      store.dispatch('merge/createLinkToken');
    }
  }
}

export const handleV2RoutesInDevMode = (to, from, next) => {
  if (isDev && to.path.startsWith('/v2')) {
    const path = to.path.replace('/v2', '');
    redirectToV2({ ...to, path });
  } else {
    return next();
  }
};
