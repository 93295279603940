import { api } from '@/axios/instance/api';
import { cloneDeep } from 'lodash-es';

const resourcesApi = '/resources';

const state = {
  resources: [],
  resource: {},
  isLoading: false,
};

const getters = {
  getResources: (state) => state.resources,
  getResource: (state) => state.resource,
  getIsLoading: (state) => state.isLoading,
};

const mutations = {
  setResources(state, resources) {
    state.resources = resources;
  },
  setResource(state, resource) {
    state.resource = resource;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  async fetchResources({ commit }) {
    const { data, error } = await api.get(resourcesApi);
    if (!error) {
      commit('setResources', data);
    }
  },
  async createResource({ commit, dispatch, state }, payload) {
    const formData = new FormData();
    formData.append('title', payload.title);
    formData.append('description', payload.description);
    formData.append('resource_type', payload.resource_type);
    formData.append('resource_url', payload.resource_url);

    if (payload.resource_type === 'link') {
      formData.append('resource_url', payload.resource_url);
    }

    if (payload.resource_type === 'file' && payload.file) {
      formData.append('resource_file', payload.file);
    }

    const response = await api.post(resourcesApi, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response.error) {
      commit('setResources', [...state.resources, response.data]);
    }
    return response;
  },
  async updateResource({ commit, dispatch }, payload) {
    const { id } = payload;
    const formData = new FormData();
    formData.append('id', id);
    formData.append('title', payload.title);
    formData.append('description', payload.description);
    formData.append('resource_type', payload.resource_type);

    if (payload.resource_type === 'link') {
      formData.append('resource_url', payload.resource_url);
    }

    if (payload.resource_type === 'file' && payload.file) {
      formData.append('resource_file', payload.file);
    }

    const response = await api.patch(`${resourcesApi}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response.error) {
      const resources = cloneDeep(state.resources);
      const resourceIndex = resources.findIndex((r) => r.id === id);
      resources[resourceIndex] = response.data;

      commit('setResources', [...resources]);
    }
    return response;
  },
  async deleteResource({ commit, dispatch }, id) {
    const response = await api.delete(`${resourcesApi}/${id}`);
    if (!response.error) {
      const resources = cloneDeep(state.resources);
      const resourceIndex = resources.findIndex((r) => r.id === id);
      resources.splice(resourceIndex, 1);
      commit('setResources', [...resources]);
    }
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
