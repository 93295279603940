import Vue from 'vue';
import store from '@/store';
import { redirectToV2 } from '@/utils/redirect';

const JobArchitecturePage = () =>
  import(/* webpackChunkName: "job-architecture-page" */ '@/settings/pages/company/job-architecture.vue');
const ApprovalChainsPage = () =>
  import(/* webpackChunkName: "approval-chains-page" */ '@/settings/pages/offer/approval-chains.vue');
const OfferLettersTemplatesPage = () =>
  import(/* webpackChunkName: "offer-letter-templates-page" */ '@/settings/pages/offer/offer-letter-templates.vue');
const ESignaturePage = () => import(/* webpackChunkName: "esignature-page" */ '@/settings/pages/offer/esignature.vue');

const DefaultLayout = () => import(/* webpackChunkName: "defaultlayout" */ '@/settings/layouts/default-layout.vue');

Vue.component('LayoutsSettingsDefault', DefaultLayout);

const beforeEnterCallbackAdmin = (to, from, next) => {
  if (store.state.auth.profile.is_admin) {
    return next();
  } else {
    next('/settings');
  }
};

const beforeEnterCallbackOfferAdmin = (to, from, next) => {
  if (store.getters['companies/isUsingOffersV1'] && store.state.auth.profile.is_admin) {
    return next();
  } else {
    next('/settings');
  }
};

const beforeEnterCallbackOfferEsignature = (to, from, next) => {
  const hasAccess =
    store.state.auth.profile.is_admin ||
    store.state.auth.profile.is_teamlead ||
    store.state.auth.profile.is_submitterapprover ||
    store.state.auth.profile.is_submitter;
  if (store.getters['companies/isUsingOffersV1'] && hasAccess) {
    return next();
  } else {
    next('/settings');
  }
};

export const settingsRoutes = [
  {
    path: '/settings/company/job-architecture',
    name: 'settings-company-job-architecture',
    component: JobArchitecturePage,
    beforeEnter: beforeEnterCallbackAdmin,
    meta: {
      layout: 'SettingsDefault',
    },
  },
  {
    path: '/settings/offer/approval-chains',
    name: 'settings-offer-approval-chains',
    component: ApprovalChainsPage,
    beforeEnter: beforeEnterCallbackOfferAdmin,
    meta: {
      layout: 'SettingsDefault',
    },
  },
  {
    path: '/settings/offer/offer-letter-templates',
    name: 'settings-offer-offer-letter-templates',
    component: OfferLettersTemplatesPage,
    beforeEnter: beforeEnterCallbackOfferAdmin,
    meta: {
      layout: 'SettingsDefault',
    },
  },
  {
    path: '/settings/offer/esignature',
    name: 'settings-offer-esignature',
    component: ESignaturePage,
    beforeEnter: beforeEnterCallbackOfferEsignature,
    meta: {
      layout: 'SettingsDefault',
    },
  },
  // Legacy route redirects
  {
    path: '/settings/docusign',
    redirect: { name: 'settings-offer-esignature' },
  },
  {
    path: '/settings*',
    beforeEnter: (to) => {
      redirectToV2(to);
    },
  },
];
