import axios from 'axios';

const protocol = window.location.protocol + '//';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-unused-vars
const host = window.location.host;
const split = window.location.host.split(':');
const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
const urlBase = protocol + newHost;

const EMPLOYEES_API = '/api/employeesv2/';

const state = {
  employees: [],
  allEmployees: [],
  count: 0,
  countFiltered: 0,
  employee: {},
  sortedEmployeeRows: [],
  peoplePageSelectElement: '',
  peoplePageSortDirection: 1,
  loadingPeopleData: false,
  employeesSet: false,
  peerComparison: {},
  matchingEmployees: [],
  contextMenuOpen: false,
  isLoading: false,
  filterLists: {},
  peoplePageFilterSelections: { selectedRegions: [], selectedRoles: [], selectedDepartments: [], selectedLevels: [] },
  isExportingPeople: false,
};

const getters = {
  getEmployees: (state) => {
    return state.employees;
  },
  count: (state) => state.count,
  countFiltered: (state) => state.countFiltered,
  getEmployee: (state) => {
    return state.employee;
  },
  getSortedEmployeeRows: (state) => {
    return state.sortedEmployeeRows;
  },
  getPeoplePageSelectElement: (state) => {
    return state.peoplePageSelectElement;
  },
  getPeoplePageSortDirection: (state) => {
    return state.peoplePageSortDirection;
  },
  getLoadingPeopleData: (state) => {
    return state.loadingPeopleData;
  },
  getEmployeesSet: (state) => {
    return state.employeesSet;
  },
  getPeerComparison: (state) => {
    return state.peerComparison;
  },
  getMatchingEmployees: (state) => {
    return state.matchingEmployees;
  },
  getContextMenuOpen: (state) => {
    return state.contextMenuOpen;
  },
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getFilterLists: (state) => {
    return state.filterLists;
  },
  getPeoplePageFilterSelections: (state) => {
    return state.peoplePageFilterSelections;
  },
  isExportingPeople: (state) => {
    return state.isExportingPeople;
  },
};

const mutations = {
  setEmployees(state, employees) {
    state.employees = employees;
  },
  setCount(state, count) {
    state.count = count;
  },
  setCountFiltered(state, countFiltered) {
    state.countFiltered = countFiltered;
  },
  setEmployee(state, employee) {
    state.employee = employee;
  },
  setSortedEmployeeRows(state, rows) {
    state.sortedEmployeeRows = rows;
  },
  addEmployees(state, employees) {
    state.employees = employees;
  },
  setMatchingEmployees(state, employees) {
    state.matchingEmployees = employees;
  },
  updateEmployee(state, employee) {
    state.employees = state.employees.filter((item) => item.id !== employee.id);
    state.employees.push(employee);
  },
  setPeoplePageSelectElement(state, element) {
    state.peoplePageSelectElement = element;
  },
  setPeoplePageSortDirection(state, direction) {
    state.peoplePageSortDirection = direction;
  },
  setLoadingPeopleData(state, boolean) {
    state.loadingPeopleData = boolean;
  },
  setEmployeesSet(state) {
    state.employeesSet = true;
  },
  setPeerComparison(state, comparison) {
    state.peerComparison = comparison;
  },
  setContextMenuOpen(state, boolean) {
    state.contextMenuOpen = boolean;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setFilterLists(state, lists) {
    lists.roles = lists.roles.map((roleArray) => {
      return { name: roleArray[0], department: roleArray[1] };
    });

    state.filterLists = lists;
  },
  setPeoplePageFilterSelections(state, selections) {
    state.peoplePageFilterSelections[selections[0]] = selections[1];
  },
  setIsExportingPeople(state, payload) {
    state.isExportingPeople = payload;
  },
};

const actions = {
  async fetchEmployees(context) {
    context.commit('setLoadingPeopleData', true);
    try {
      const response = await axios.get(urlBase + EMPLOYEES_API);
      const visibleEmployees = response.data.results;
      const sortedEmployees = visibleEmployees.sort((a, b) => {
        return a.created_at < b.created_at ? 1 : -1;
      });

      context.commit('setEmployees', visibleEmployees);
      context.commit('setCount', response.data.count);
      context.commit('setSortedEmployeeRows', sortedEmployees);
      context.commit('setEmployeesSet');
      context.commit('setPeoplePageSelectElement', '');
      context.commit('setLoadingPeopleData', false);
    } catch (error) {
      console.log(error);
      context.commit('setLoadingPeopleData', false);
    }
  },
  async fetchEmployee({ commit }, id) {
    commit('setLoadingPeopleData', true);
    try {
      const response = await axios.get(urlBase + EMPLOYEES_API + `${id}`);
      commit('setEmployee', response.data);
      commit('setLoadingPeopleData', false);
    } catch (error) {
      // change this so it helps user
      console.log(error);
      commit('setLoadingPeopleData', false);
    }
  },
  async fetchMatchingEmployees(context, data) {
    try {
      const response = await axios.get(`${urlBase}${EMPLOYEES_API}`, { params: data });
      const visibleEmployees = response.data.results;
      context.commit('addEmployees', visibleEmployees);
      context.commit('setMatchingEmployees', visibleEmployees);
      context.commit('setCount', response.data.count);
      context.commit('setLoadingPeopleData', false);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPeerMedians(context, data) {
    try {
      const response = await axios.get(`${urlBase}${EMPLOYEES_API}peer-medians`, { params: data });
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  setMatchingEmployees(context, employees) {
    context.commit('setMatchingEmployees', employees);
  },
  setSortedEmployeeRows(context, rows) {
    context.commit('setSortedEmployeeRows', rows);
  },
  updateEmployee(context, employee) {
    context.commit('updateEmployee', employee);
  },
  setPeoplePageSelectElement(context, element) {
    context.commit('setPeoplePageSelectElement', element);
  },
  setPeoplePageSortDirection(context, direction) {
    context.commit('setPeoplePageSortDirection', direction);
  },
  setLoadingPeopleData(context, boolean) {
    context.commit('setLoadingPeopleData', boolean);
  },
  async createEmployee({ commit, dispatch }, payload) {
    try {
      await axios.post(urlBase + EMPLOYEES_API, payload);
      await dispatch('fetchEmployees');
    } catch (error) {
      // add errorMsg and isLoading to state
      console.log(error);
      throw error;
    }
  },
  async editEmployee({ dispatch }, payload) {
    try {
      await axios.put(urlBase + EMPLOYEES_API + `${payload.id}`, payload);
      await dispatch('fetchEmployees');
    } catch (error) {
      console.log(error);
    }
  },
  async deleteEmployee({ dispatch }, id) {
    try {
      await axios.delete(urlBase + EMPLOYEES_API + `${id}`, { data: { id } });
      await dispatch('fetchEmployees');
    } catch (error) {
      console.log(error);
    }
  },
  setPeerComparison({ commit, rootGetters }, comparison) {
    if (rootGetters['companies/companies'][0].show_peer_comparison) {
      commit('setPeerComparison', comparison);
    } else {
      commit('setPeerComparison', {
        medianSalary: 0,
        medianEquity: 0,
        medianBonus: 0,
        medianTotalComp: 0,
        numberOfPeers: 0,
      });
    }
  },
  async exportEmployees() {
    const response = await axios.get(urlBase + EMPLOYEES_API + 'export');
    const blob = new Blob([response.data], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'employees.csv';
    link.click();
  },
  setContextMenuOpen(context, boolean) {
    context.commit('setContextMenuOpen', boolean);
  },
  async deleteAllEmployees({ commit }) {
    commit('setLoadingPeopleData', true);
    try {
      await axios.get(urlBase + EMPLOYEES_API + 'delete_all');
      commit('setLoadingPeopleData', false);
    } catch (error) {
      console.log(error); // add error msg, make it as flag so it can help the user
      commit('setLoadingPeopleData', false);
    }
  },
  async fetchFilterLists(context) {
    try {
      const response = await axios.get(urlBase + EMPLOYEES_API + 'get_value_lists');

      context.commit('setFilterLists', response.data);
    } catch (error) {
      console.log(error);
    }
  },
  setPeoplePageFilterSelections(context, selections) {
    context.commit('setPeoplePageFilterSelections', selections);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
