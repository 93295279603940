import Vue from 'vue';
import Vuex from 'vuex';

import analytics from '@/store/modules/analytics';
import approvalChains from '@/store/modules/approvalChains';
import ats from '@/store/modules/ats.js';
import auth from '@/store/modules/auth';
import candidates from '@/store/modules/candidates';
import companies from '@/store/modules/companies';
import competingOffersTable from '@/store/modules/competingOffersTable';
import employees from '@/store/modules/employees';
import globalLoading from '@/store/modules/globalLoading';
import help from '@/store/modules/help';
import marketData from '@/store/modules/marketData';
import merge from '@/store/modules/merge';
import offerActivity from '@/store/modules/offerActivity';
import offerFilter from '@/store/modules/offerFilter';
import offerLetterGenerator from '@/store/modules/offerLetterGenerator';
import offers from '@/store/modules/offers';
import ranges from '@/store/modules/ranges';
import resources from '@/store/modules/resources';
import salary from '@/store/modules/salary-board/salary.js';
import salaryBoard from '@/store/modules/salary-board/salaryBoard.js';
import users from '@/store/services/users';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    users,
    auth,
    candidates,
    marketData,
    offerFilter,
    offers,
    ranges,
    employees,
    companies,
    approvalChains,
    resources,
    globalLoading,
    merge,
    offerLetterGenerator,
    analytics,
    offerActivity,
    competingOffersTable,
    ats,
    salaryBoard,
    salary,
    help,
  },
});

export default store;
