import axios from 'axios';
import { apiCancelDocument, apiFetchSigningDocument, apiGetDocument, apiSendDocument } from '@/offers/api/dashboard';
import {
  apiCreateTemplate,
  apiDeleteTemplate,
  apiFetchTemplate,
  apiUpdateTemplate,
} from '@/offers/api/offer-templates';
import { apiDeleteDocusignIntegration, apiFetchDocuSignUserIntegrations } from '@/offers/api/docusign';
import {
  apiFetchPlaceholders,
  apiFetchTemplates,
  apiGenerateOfferLetter,
  apiIntegrateWithDocusign,
} from '@/offers/api/offers-common';

const state = {
  templates: [],
  selectedTemplates: [],
  placeholders: [],
  docusignUserIntegrations: [],
  signingDocuments: [],
  signingDocument: {},
  isLoading: false,
  isDocusignUserLoading: false,
  isDocusignBeingAuthorized: false,
  isSigningDocumentsLoading: false,
  error: {},
};

const getters = {
  getTemplates: (state) => state.templates,
  getSelectedTemplates: (state) => state.selectedTemplates,
  getPlaceholders: (state) => state.placeholders,
  getDocuSignUserIntegrations: (state) => state.docusignUserIntegrations,
  getSigningDocuments: (state) => state.signingDocuments,
  getSigningDocument: (state) => state.signingDocument,
  getIsLoading: (state) => state.isLoading,
  getIsDocusignUserLoading: (state) => state.isDocusignUserLoading,
  getIsDocusignBeingAuthorized: (state) => state.isDocusignBeingAuthorized,
  getIsSigningDocumentsLoading: (state) => state.isSigningDocumentsLoading,
  getError: (state) => state.error,
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setSelectedTemplates(state, templates) {
    state.selectedTemplates = templates;
  },
  setPlaceholders(state, placeholders) {
    state.placeholders = placeholders;
  },
  setDocuSignUserIntegrations(state, integrations) {
    state.docusignUserIntegrations = integrations;
  },
  setSigningDocuments(state, documents) {
    state.signingDocuments = documents;
  },
  setSigningDocument(state, documents) {
    state.signingDocument = documents.length > 0 ? documents[0] : {};
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
  setIsDocusignUserLoading(state, loading) {
    state.isDocusignUserLoading = loading;
  },
  setIsDocusignBeingAuthorized(state, authorizing) {
    state.isDocusignBeingAuthorized = authorizing;
  },
  setIsSigningDocumentsLoading(state, loading) {
    state.isSigningDocumentsLoading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const userClientDownload = (responseData, filename) => {
  const url = URL.createObjectURL(new Blob([responseData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const actions = {
  async createTemplate({ commit, state }, payload) {
    commit('setIsLoading', true);
    const formData = new FormData();
    formData.append('template_name', payload.templateName);
    formData.append('file', payload.file);
    const { data: template, error } = await apiCreateTemplate(formData);
    if (!error) {
      commit('setTemplates', [...state.templates, template]);
      commit('setIsLoading', false);
      payload.onSuccess();
    } else {
      commit('setError', error);
      commit('setIsLoading', false);
      payload.onError();
    }
  },
  async updateTemplate({ commit, state }, payload) {
    commit('setIsLoading', true);
    const formData = new FormData();
    formData.append('template_name', payload.templateName);
    formData.append('file', payload.file);
    const { data: template, error } = await apiUpdateTemplate(payload.templateId, formData);
    if (!error) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line eqeqeq
      const filteredTemplates = [...state.templates].filter((t) => t.id != template.id);
      commit('setTemplates', [...filteredTemplates, template]);
      commit('setIsLoading', false);
      payload.onSuccess();
    } else {
      commit('setError', error);
      commit('setIsLoading', false);
      payload.onError();
    }
  },
  async generateOfferLetter({ commit }, payload) {
    try {
      commit('setIsLoading', true);
      const resp = await apiGenerateOfferLetter(payload);
      if (!resp?.error) {
        const filename = JSON.parse(resp.response.headers['content-disposition'].replace('attachment; filename=', ''));
        userClientDownload(resp.response.data, filename);
        payload.onSuccess();
      } else {
        const decoder = new TextDecoder('utf-8');
        const errorResponse = JSON.parse(decoder.decode(resp.error?.response?.data));
        commit('setError', resp?.error);
        payload.onError(errorResponse);
      }
    } catch (error) {
      commit('setError', error);
      payload.onError();
    } finally {
      commit('setIsLoading', false);
    }
  },
  async fetchTemplates({ commit }) {
    try {
      commit('setIsLoading', true);
      const { data } = await apiFetchTemplates();
      commit('setTemplates', data);
      commit('setIsLoading', false);
    } catch (error) {
      commit('setError', error);
      commit('setIsLoading', false);
    }
  },
  async fetchDocuSignUserIntegrations({ commit }, userId) {
    try {
      commit('setIsDocusignUserLoading', true);
      const { data } = await apiFetchDocuSignUserIntegrations(userId);
      commit('setDocuSignUserIntegrations', data);
      commit('setIsDocusignUserLoading', false);
    } catch (error) {
      commit('setError', error);
      commit('setIsDocusignUserLoading', false);
    }
  },
  async fetchSigningDocument({ commit }, offerId) {
    try {
      commit('setIsSigningDocumentsLoading', true);
      const { data } = await apiFetchSigningDocument(offerId);
      commit('setSigningDocument', data);
      commit('setIsSigningDocumentsLoading', false);
    } catch (error) {
      commit('setError', error);
      commit('setIsSigningDocumentsLoading', false);
    }
  },
  fetchTemplate(_, id) {
    return apiFetchTemplate(id);
  },
  async downloadTemplate({ commit }, payload) {
    try {
      commit('setIsLoading', true);
      const response = await axios.create().get(payload.downloadUrl, {
        responseType: 'blob',
      });
      userClientDownload(response.data, payload.filename);
      commit('setIsLoading', false);
      payload.onSuccess();
    } catch (error) {
      commit('setError', error);
      commit('setIsLoading', false);
      payload.onError();
    }
  },
  async deleteTemplate({ commit, state }, payload) {
    try {
      commit('setIsLoading', true);
      await apiDeleteTemplate(payload.id);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line eqeqeq
      const filteredTemplates = [...state.templates].filter((t) => t.id != payload.id);
      commit('setTemplates', [...filteredTemplates]);
      commit('setIsLoading', false);
      payload.onSuccess();
    } catch (error) {
      commit('setError', error);
      commit('setIsLoading', false);
      payload.onError();
    }
  },
  async fetchPlaceholders({ commit }) {
    try {
      commit('setIsLoading', true);
      const { data } = await apiFetchPlaceholders();
      commit('setPlaceholders', data);
      commit('setIsLoading', false);
    } catch (error) {
      commit('setError', error);
      commit('setIsLoading', false);
    }
  },
  async integrateWithDocusignAccount({ commit }, payload) {
    try {
      commit('setIsDocusignBeingAuthorized', true);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-unused-vars
      const response = await apiIntegrateWithDocusign(payload);
      commit('setIsDocusignBeingAuthorized', false);
      payload.onSuccess();
    } catch (error) {
      commit('setError', error);
      commit('setIsDocusignBeingAuthorized', false);
      payload.onError();
    }
  },
  async deleteDocusignIntegration({ commit, state }, payload) {
    try {
      commit('setIsDocusignUserLoading', true);
      await apiDeleteDocusignIntegration(payload);
      const filteredIntegrations = [...state.docusignUserIntegrations].filter((t) => t.id !== payload.id);
      commit('setDocuSignUserIntegrations', [...filteredIntegrations]);
      commit('setIsDocusignUserLoading', false);
      payload.onSuccess();
    } catch (error) {
      commit('setError', error);
      commit('setIsDocusignUserLoading', false);
      payload.onError();
    }
  },
  async sendDocument({ commit }, payload) {
    try {
      commit('setIsSigningDocumentsLoading', true);
      await apiSendDocument(payload);
      commit('setIsSigningDocumentsLoading', false);
      payload.onSuccess();
    } catch (error) {
      commit('setError', error);
      commit('setIsSigningDocumentsLoading', false);
      payload.onError();
    }
  },

  async cancelDocument({ commit }, payload) {
    try {
      commit('setIsSigningDocumentsLoading', true);
      await apiCancelDocument(payload);
      commit('setSigningDocument', []);
      commit('setIsSigningDocumentsLoading', false);
      payload.onSuccess();
    } catch (error) {
      commit('setError', error);
      commit('setIsSigningDocumentsLoading', false);
      payload.onError();
    }
  },

  async getDocument({ commit }, documentId) {
    try {
      const { data: url } = await apiGetDocument(documentId);
      return url;
    } catch (error) {
      console.log('Could not get a valid url for the document.', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
