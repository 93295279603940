import CompetingOfferHelpers from '@/utils/competingOffers';
import { OFFER_STATUS } from '@/enums';
import {
  apiApproveOffer,
  apiCreateOfferApprovalChain,
  apiCreateOfferApprovalLinks,
  apiCreateOfferApprovers,
  apiFetchOfferApprovalChainData,
  apiRejectOffer,
  apiSendAssignedAnOfferMail,
  apiUpdateOfferApprovalChain,
  apiUpdateOfferApprovalLink,
  apiUpdateOfferApprover,
  apiUpdateOfferCustomFields,
  fetchOfferApprovalLinkData,
  fetchOfferApproverData,
} from '@/offers/api/dashboard';
import {
  apiCreateOffer,
  apiEditOffer,
  apiFetchCustomFields,
  apiFetchEquityVestScheduleTypes,
  apiFetchOfferApprovalChains,
  apiFetchOfferApprovalLinks,
  fetchOffer,
} from '@/offers/api/offers-common';
import { apiResubmitOffer, apiSubmitOffer } from '@/offers/api/offer-form';
import { getField, updateField } from 'vuex-map-fields';
import { getOfferStats } from '@/store/services/offers';

const EMPTY_OFFER_RANGE = {
  offer: null,
  total_ranges: null,
  range_q: null,
  range_name: null,
  range_calc: null,
  annual_salary: null,
  hourly_salary: null,
  four_year_equity_currency: null,
  bonus_percent: null,
  bonus_currency: null,
  average_otp_items: null,
  average_otp_total: null,
  average_otp_percent: null,
  tac: null,
  annual_equity_currency: null,
  equity_shares: null,
  equity_percent: null,
};

const EMPTY_OFFER_PEER = {
  offer: null,
  average_annual_salary: null,
  salary_difference: null,
  compensation_type: null,
  average_annual_equity_currency: null,
  average_four_year_equity_currency: null,
  equity_difference: null,
  average_bonus_percent: null,
  average_bonus_currency: null,
  bonus_difference: null,
  average_otp_items: null,
  average_otp: null,
  average_tac: null,
  tac_difference: null,
  number_of_peers: null,
  average_annual_equity_percent: null,
  average_hourly_salary: null,
  average_annual_equity_shares: null,
};

const state = {
  pageConfig: null,
  offerStats: null,
  isOfferLoading: false,
  offer: {},
  offerForUpdate: {},
  offerDocument: null,
  hasErrorOfferDocument: false,
  offerApprovalLinks: [],
  offerApprovalLink: {},
  offerApprovalChains: [],
  offerApprovers: [],
  custom_fields: [],
  custom_field: {},
  offerApprovalChain: {},
  offerApprovalChainsSet: false,
  offerApprovalLinksSet: false,
  offerApproversSet: false,
  isLoading: false,
  isLoadingApprovals: false,
  offerActivities: [],
  isLoadingOfferActivity: false,

  equityVestScheduleType: [],
};

const getters = {
  getField,
  tenant: (state, getters, rootState, rootGetters) => rootGetters['companies/companies'][0],
  user: (state, getters, rootState, rootGetters) => rootGetters['auth/getProfile'],
  offerStats: (state) => state.offerStats,
  isOfferLoading: (state) => state.isOfferLoading,
  getOffer: (state) => {
    return state.offer;
  },
  offerForUpdate: (state) => {
    return state.offerForUpdate;
  },
  getOfferDocument: (state) => state.offerDocument,
  getHasErrorOfferDocument: (state) => state.hasErrorOfferDocument,
  getOfferApprovalLinks: (state) => {
    return state.offerApprovalLinks;
  },
  getOfferApprovalLink: (state) => {
    return state.offerApprovalLink;
  },
  getCustomFields: (state) => state.custom_fields,
  getCustomField: (state) => state.custom_field,
  getOfferApprovalChains: (state) => {
    return state.offerApprovalChains;
  },
  getOfferApprovers: (state) => {
    return state.offerApprovers;
  },
  getOfferApprovalChain: (state) => {
    return state.offerApprovalChain;
  },
  equityVestScheduleType: (state) => state.equityVestScheduleType,
  getOfferActivity: (state) => state.offerActivities,
  getIsLoadingOfferActivity: (state) => state.isLoadingOfferActivity,
  isLoading: (state) => state.isLoading,
  isLoadingApprovals: (state) => state.isLoadingApprovals,
  competingOffers: (state) => (state.offer ? state.offer.competing_offers : null),
  highestGrossingCompetingOffer: (_, getters) => {
    const competingOffer = (getters.competingOffers || []).reduce((highestGrossingOffer, offer) => {
      if (CompetingOfferHelpers.getTotalComp(offer) > CompetingOfferHelpers.getTotalComp(highestGrossingOffer)) {
        return offer;
      }
      return highestGrossingOffer;
    }, {});

    return competingOffer;
  },
};

const mutations = {
  updateField,
  setPageConfig(state, pageConfig) {
    state.pageConfig = pageConfig;
  },
  setIsOfferLoading(state, isOfferLoading) {
    state.isOfferLoading = isOfferLoading;
  },
  setOfferStats(state, offerStats) {
    state.offerStats = offerStats;
  },
  setOffer(state, offer) {
    if ((offer && Object.keys(offer).length === 0) || (offer && offer.latest_offer)) {
      state.offer = offer;
    }
  },
  setOfferForUpdate(state, offer) {
    if ((offer && Object.keys(offer).length === 0) || (offer && offer.latest_offer)) {
      state.offerForUpdate = offer;
    }
  },
  setOfferDocument(state, offerDocument) {
    state.offerDocument = offerDocument;
  },
  setHasErrorOfferDocument(state, hasErrorOfferDocument) {
    state.hasErrorOfferDocument = hasErrorOfferDocument;
  },
  setOfferApprovalLinks(state, links) {
    state.offerApprovalLinks = links;
  },
  addOfferApprovalLinks(state, links) {
    state.offerApprovalLinks.push(links);
    state.offerApprovalLinks = state.offerApprovalLinks.flat();
  },
  updateOfferApprovalLink(state, payload) {
    state.offerApprovalLinks = state.offerApprovalLinks.filter((link) => link.id !== payload.id);
    state.offerApprovalLinks.push(payload);
  },
  deleteOfferApprovalLink(state, offerApprovalChainLinkId) {
    state.offerApprovalLinks = state.offerApprovalLinks.filter((elem) => elem.id !== offerApprovalChainLinkId);
  },
  setOfferApprovalLink(state, link) {
    state.offerApprovalLink = link;
  },
  setCustomFields(state, custom_fields) {
    state.custom_fields = custom_fields;
  },
  setCustomField(state, custom_field) {
    state.custom_field = custom_field;
  },
  updateCustomField: (state, payload) => {
    const val = Object.keys(payload)[1];
    const customfield = state.custom_field;
    customfield[val] = payload[1];
  },
  setOfferApprovalChains(state, payload) {
    state.offerApprovalChains = payload;
  },
  setOfferApprovers(state, payload) {
    state.offerApprovers = payload;
  },
  addOfferApprovalChain(state, chain) {
    state.offerApprovalChains.push(chain);
  },
  addOfferApprovers(state, approvers) {
    state.offerApprovers.push(approvers);
    state.offerApprovers = state.offerApprovers.flat();
  },
  deleteOfferApprover(state, approverId) {
    state.offerApprovers = state.offerApprovers.filter((elem) => elem.id !== approverId);
  },
  setOfferApprovalChain(state, chain) {
    state.offerApprovalChain = chain;
  },
  updateOfferApprover(state, approver) {
    state.offerApprovers = state.offerApprovers.filter((a) => a.id !== approver.id);
    state.offerApprovers.push(approver);
  },
  updateOfferApprovalChain(state, chain) {
    state.offerApprovalChains = state.offerApprovalChains.filter((c) => c.id !== chain.id);
    state.offerApprovalChains.push(chain);
  },
  offerApprovalChainsSet(state, isSet = true) {
    state.offerApprovalChainsSet = isSet;
  },
  offerApprovalLinksSet(state, isSet = true) {
    state.offerApprovalLinksSet = isSet;
  },
  offerApproversSet(state, isSet = true) {
    state.offerApproversSet = isSet;
  },
  deleteOfferApprovalChain(chainID) {
    state.offerApprovalChains.filter((c) => c.id !== chainID);
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
  setIsLoadingApprovals(state, loadingApprovals) {
    state.isLoadingApprovals = loadingApprovals;
  },
  setCompetingOffers(state, competingOffers) {
    state.offer.competing_offers = competingOffers;
  },
  resetOfferRanges(state) {
    if (!state.pageConfig) {
      return;
    }
    const emptyRanges = state.pageConfig.ranges.defaults.map((rangeDefault) => {
      return {
        ...EMPTY_OFFER_RANGE,
        ...rangeDefault,
      };
    });

    state.offer = { ...state.offer, ranges: emptyRanges };
  },
  resetOfferPeerData(state) {
    state.offer = { ...state.offer, peer: EMPTY_OFFER_PEER };
  },
  setApprovalChainData(state, { approval_chains, latest_approval_chain, approval_links, approvers }) {
    state.offerApprovalChains = approval_chains;
    state.offerApprovalChain = latest_approval_chain;
    state.offerApprovalLinks = approval_links;
    state.offerApprovers = approvers;
  },
  setEquityVestScheduleType(state, equityVestScheduleType) {
    state.equityVestScheduleType = equityVestScheduleType;
  },
};

const actions = {
  async getOfferStats({ commit }) {
    const { data, error } = await getOfferStats();

    if (!error) {
      commit('setOfferStats', data);
    } else {
      console.error('Failed to fetch offer status!', error);
    }
  },
  async getOffer(context, offerId) {
    context.commit('setIsOfferLoading', true);
    try {
      const { data } = await fetchOffer(offerId);
      context.commit('setOffer', data);
    } catch (error) {
      console.log(error);
    }
    context.commit('setIsOfferLoading', false);
  },
  setOffer({ commit, dispatch }, offer) {
    commit('setOffer', offer);
    dispatch('fetchOfferApprovalChainData');
  },
  async createOffer(context, payload) {
    context.commit('setIsOfferLoading', true);
    const { data } = await apiCreateOffer(payload);
    context.commit('setOffer', data);
    context.commit('setIsOfferLoading', false);
    const newStatus = payload.is_approved ? OFFER_STATUS.APPROVED : OFFER_STATUS.DRAFT;
    context.commit('offerFilter/setSelectedOfferStatus', newStatus, { root: true });
  },
  async editOffer({ commit }, payload) {
    commit('setIsLoading', true);
    commit('setIsOfferLoading', true);
    try {
      const { data } = await apiEditOffer(payload);
      if (data.latest_offer) {
        commit('setOffer', data);
      }
    } catch (error) {
      console.error(`Failed to patch the offer: ${payload.id}!`, error);
    }
    commit('setIsLoading', false);
    commit('setIsOfferLoading', false);
  },
  async submitOffer({ commit }, payload) {
    try {
      const { data } = await apiSubmitOffer(payload);
      commit('setOffer', data);
    } catch (e) {
      console.error(`Failed to submit offer ${payload.id}`);
    }
  },
  async resubmitOffer({ commit }, payload) {
    try {
      const { data } = await apiResubmitOffer(payload);
      commit('setOffer', data);
    } catch (e) {
      console.error(`Failed to resubmit offer ${payload.offerId}`);
    }
  },
  async updateOfferCustomFields({ commit, dispatch, state }, payload) {
    try {
      const { data } = await apiUpdateOfferCustomFields(payload);

      if (data.latest_offer) {
        commit('setOffer', data);
      }
      payload.onSuccess();
    } catch (e) {
      payload.onError(e.response.status);
      console.error(`Failed to update custom fields for offer "${payload.id}"`, e);
    }
  },
  async createOfferApprovalLinks({ commit }, payload) {
    try {
      const { data } = await apiCreateOfferApprovalLinks(payload);
      commit('addOfferApprovalLinks', data);
    } catch (error) {
      console.error(error);
    }
  },
  async updateOfferApprovalLink({ commit }, payload) {
    try {
      const { data } = await apiUpdateOfferApprovalLink(payload);
      commit('updateOfferApprovalLink', data);
    } catch (error) {
      console.error(`Failed to update approval link: ${payload.id}`, error);
    }
  },
  async setOfferApprovalLink(context, link) {
    try {
      context.commit('setOfferApprovalLink', link);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCustomFields({ commit }) {
    try {
      const { data } = await apiFetchCustomFields();
      commit('setCustomFields', data);
    } catch (error) {
      console.error(error);
    }
  },
  setCustomfield(context, payload) {
    context.commit('setCustomField', payload);
  },
  async fetchOfferApprovalChainData({ commit }, originalOfferId = null) {
    // originalOfferId is always null, so, gl&hf :D
    // TODO: Reduce to a single request!
    originalOfferId = originalOfferId === null ? state.offer.original_offer : originalOfferId;

    if (!originalOfferId) {
      return;
    }

    commit('offerApprovalChainsSet', false);
    const { data } = await apiFetchOfferApprovalChainData(originalOfferId);
    commit('setOfferApprovalChains', data);
    commit('offerApprovalChainsSet');

    if (data.length > 0) {
      commit('offerApproversSet', false);
      commit('offerApprovalLinksSet', false);

      const offerApprovalChain = data.sort((a, b) => b.version - a.version)[0];
      commit('setOfferApprovalChain', offerApprovalChain);

      const { data: oalData } = await fetchOfferApprovalLinkData(offerApprovalChain.id);

      commit('setOfferApprovalLinks', oalData);
      commit('offerApprovalLinksSet');

      const { data: oaData } = await fetchOfferApproverData(offerApprovalChain.id);

      commit('setOfferApprovers', oaData);
      commit('offerApproversSet');
    } else {
      const { data } = await apiFetchOfferApprovalChainData(state.offer.original_offer);
      if (data.length > 0) {
        const offerApprovalChain = data.sort((a, b) => b.version - a.version)[0];
        commit('setOfferApprovalChain', offerApprovalChain);

        // This is almost certainly not working, missing "await"
        const { data: oalData } = fetchOfferApprovalLinkData(offerApprovalChain.id);

        commit('setOfferApprovalLinks', oalData);
        commit('offerApprovalLinksSet');

        // This as well, but I'm too afraid to touch it
        const { data: oaData } = fetchOfferApproverData(offerApprovalChain.id);

        commit('setOfferApprovers', oaData); // oaData is esentially a promise at this point
        commit('offerApproversSet');
      }
    }
  },
  fetchOfferApprovalChains(context) {
    apiFetchOfferApprovalChains().then((response) => {
      context.commit('setOfferApprovalChains', response.data);
      context.commit('offerApprovalChainsSet');
    });
  },
  fetchOfferApprovalLinks(context) {
    apiFetchOfferApprovalLinks().then((response) => {
      context.commit('setOfferApprovalLinks', response.data);
      context.commit('offerApprovalLinksSet');
    });
  },
  async createOfferApprovalChain({ commit }, payload) {
    try {
      const { data } = await apiCreateOfferApprovalChain(payload);
      commit('addOfferApprovalChain', data);
      commit('setOfferApprovalChain', data);
    } catch (error) {
      console.error(error);
    }
  },
  async createOfferApprovers({ commit }, payload) {
    try {
      const { data } = await apiCreateOfferApprovers(payload);
      commit('addOfferApprovers', data);
    } catch (error) {
      console.error(error);
    }
  },
  async updateOfferApprover({ commit }, payload) {
    try {
      const { data } = apiUpdateOfferApprover(payload);
      commit('updateOfferApprover', data);
    } catch (error) {
      console.error(error);
    }
  },
  async updateOfferApprovalChain({ commit }, payload) {
    try {
      const { data } = await apiUpdateOfferApprovalChain(payload);
      commit('updateOfferApprovalChain', data);
    } catch (error) {
      console.error(error);
    }
  },
  setOfferApprovalChain(context, chain) {
    context.commit('setOfferApprovalChain', chain);
  },
  sendAssignedAnOfferMail({ commit }, payload) {
    apiSendAssignedAnOfferMail(payload);
  },
  async fetchEquityVestScheduleTypes(context) {
    try {
      const { data } = await apiFetchEquityVestScheduleTypes();
      context.commit('setEquityVestScheduleType', data);
    } catch (e) {
      console.log('Failed to fetch Equity vest schedule types!', e);
    }
  },
  async approveOffer(context, { id, payload }) {
    try {
      const { data } = await apiApproveOffer(id, payload);
      context.commit('setOffer', data);
    } catch (e) {
      console.error(`Failed to approve the offer ${id}`);
    }
  },
  async rejectOffer(context, { id, payload }) {
    try {
      const { data } = await apiRejectOffer(id, payload);
      context.commit('setOffer', data);
    } catch (e) {
      console.error(`Failed to reject the offer ${id}!`);
    }
  },
  updateOfferApprovalLinks({ commit }, offerApprovalLinks) {
    offerApprovalLinks.forEach((offerApprovalLink) => commit('updateOfferApprovalLink', offerApprovalLink));
  },
  addOfferApprovalLink({ commit }, offerApprovalLink) {
    commit('addOfferApprovalLinks', [offerApprovalLink]);
  },
  addOfferApprovers({ commit }, offerApprovers) {
    commit('addOfferApprovers', offerApprovers);
  },
  addOfferApprover({ commit }, offerApprover) {
    commit('addOfferApprovers', [offerApprover]);
  },
  deleteOfferApprover({ commit }, approverId) {
    commit('deleteOfferApprover', approverId);
  },
  addOfferApprovalLinks({ commit }, offerApprovalLinks) {
    commit('addOfferApprovalLinks', offerApprovalLinks);
  },

  deleteOfferApprovalLink({ commit, state }, offerApprovalChainLinkId) {
    commit('deleteOfferApprovalLink', offerApprovalChainLinkId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
