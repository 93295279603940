export const PAGE_SIZE = 20;

export const FIRST_PAGE = 1;

export const ACCESS_TOKEN = 'access_token';
export const ACCESS_COOKIE_NAME = 'X-Authorization';
export const REFRESH_TOKEN = 'refresh_token';
export const TOKENS_EXPIRY_ERROR = 'All tokens expired';
export const TOKENS_INVALID_ERROR = 'Tokens are invalid';
export const REFRESH_ROUTE = '/token/access/';

export const NAVBAR_SPINNER_SIZE = 20;

export const DATE_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric' };
export const MARKETING_WEBSITE_URL = 'https://pequity.com';

export const OFFERS_PERMISSION_VALUES = {
  NO_ACCESS: 0,
  APPROVER: 1,
  SUBMITTER: 2,
  SUBMITTER_APPROVER: 3,
};

export const FEATURE_PERMISSIONS_VALUES = {
  NO_ACCESS: 0,
  ACCESS: 1,
};
