<template>
  <div class="root">
    <Component :is="layout" v-if="layout">
      <RouterView />
    </Component>
    <RouterView v-else />
    <div v-if="isLoading" class="global-loading">
      <RingLoader />
      <span>{{ loadingText }}</span>
    </div>
    <HelpWidget />
  </div>
</template>

<script>
import HelpWidget from '@/help/components/help-widget.vue';
import RingLoader from '@/components/atoms/ring-loader.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {
    RingLoader,
    HelpWidget,
  },
  computed: {
    ...mapGetters('globalLoading', { isLoading: 'getIsLoading', loadingText: 'getLoadingText' }),
    ...mapGetters('offerFilter', {
      selectedOfferStatus: 'selectedOfferStatus',
    }),
    ...mapGetters('help', ['helpComponentName']),
    layout() {
      return this.$route.meta.layout ? `Layouts${this.$route.meta.layout}` : null;
    },
  },
  watch: {
    $route: {
      // This should be in the sidebar component, but this is the common component
      deep: true,
      handler(route) {
        if (!route.path.startsWith('/dashboard')) {
          this.setSelectedOfferStatus(null);
        }
      },
    },
  },
  mounted() {
    // Remove the splash loader (public/index.html)
    setTimeout(() => {
      document.querySelector('#p-splash-loader').classList.add('p-splash-fade-out');
      setTimeout(() => {
        document.querySelector('#p-splash-loader').remove();
      }, 400);
    }, 300);
  },
  methods: {
    ...mapMutations('offerFilter', {
      setSelectedOfferStatus: 'setSelectedOfferStatus',
    }),
  },
};
</script>

<style lang="scss" scoped>
.root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.global-loading {
  position: fixed;
  top: 0;
  left: 90px;
  width: calc(100% - 90px);
  height: 100%;
  background: #ffffff;
  @apply flex flex-col items-center justify-center;
  gap: 16px;
  opacity: 0.8;
  z-index: 100;
}
</style>
